<template>
  <div>
    <a-select
      :value="selectedValue"
      :size="size"
      :placeholder="placeholder"
      :allowClear="allowClear"
      style="width: 100%"
      @change="changeValue"
    >
      <a-select-option v-for="item in dataItems" :key="item.id" :value="item.id">
        {{ item.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { crossingOptionList } from "@/apis/option";

export default {
  props: ["value", "size", "placeholder", "allowClear", "productionLine"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      selectedValue: undefined,
      dataItems: [],
    };
  },
  methods: {
    list() {
      crossingOptionList({ production_line: this.productionLine }).then((data) => (this.dataItems = data));
    },
    changeValue(value) {
      this.selectedValue = value;
      this.$emit("change", value);
    },
  },
  mounted() {
    if (this.value !== undefined) {
      this.selectedValue = this.value;
    }

    this.list();
  },
  watch: {
    productionLine() {
      this.list();

      if (this.selectedValue !== undefined) {
        this.selectedValue = undefined;
        this.$emit("change", undefined);
      }
    },
  },
};
</script>

<style scoped></style>
